import React, { useContext } from "react";
import { UserContext } from "../../firebase/userContext";
import './settings.css';
import { useNavigate } from "react-router-dom";

const Settings = () => {
    const { handleDeleteAccount } = useContext(UserContext);
    const navigate = useNavigate();
    
    

    return (
        <div className="register-layout">
            <h1 id="logo">JUSSST</h1>
            <div id="settings-container">
               <h1 id="settings-header">Settings</h1>
               <p>about</p>
               <p>privacy</p>
               
               <p onClick={() => navigate('/login')} 
               
                >log out</p>
               
               <p id="delete-button" 
               onClick={handleDeleteAccount}
              
              
               
               >Delete account</p>
            </div>
        </div>
    );
}

export default Settings;
