import React, { useState, useRef, useEffect } from "react";
import './musicPlayer.css'; // Ensure CSS styles are not conflicting
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const MusicPlayer = ({ fileURL, className, isPlaying, onPlay, onPause }) => {
    const audioRef = useRef(null); // Reference to the audio element
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    // Effect to handle playback and metadata updates
    useEffect(() => {
        const audioElement = audioRef.current;

        if (audioElement) {
            console.log("Audio element loaded:", audioElement);

            if (isPlaying) {
                audioElement.play().catch(error => console.error("Play failed:", error));
                if (onPlay) onPlay();
            } else {
                audioElement.pause();
                if (onPause) onPause();
            }
            
            const updateMetadata = () => {
                setDuration(audioElement.duration);
                console.log("Audio duration:", audioElement.duration);
            };
            const updateProgress = () => {
                setCurrentTime(audioElement.currentTime);
                console.log("Current time:", audioElement.currentTime);
            };

            audioElement.addEventListener("loadedmetadata", updateMetadata);
            audioElement.addEventListener("timeupdate", updateProgress);

            return () => {
                audioElement.removeEventListener("loadedmetadata", updateMetadata);
                audioElement.removeEventListener("timeupdate", updateProgress);
            };
        }
    }, [fileURL, isPlaying, onPlay, onPause]);

    // Effect to reset playback when fileURL changes
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.pause();
            setCurrentTime(0);
            console.log("Playback reset for new fileURL.");
        }
    }, [fileURL]);

    // Format time to MM:SS
    const formatTime = (time) => {
        if (isNaN(time)) return "00:00";
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    // Toggle play/pause state
    const togglePlayPause = () => {
        console.log("Toggle play/pause:", isPlaying);
        if (isPlaying) {
            audioRef.current.pause();
            if (onPause) onPause();
        } else {
            audioRef.current.play().catch(error => console.error("Play failed:", error));
            if (onPlay) onPlay();
        }
    };

    return (
        <div className={`music-player ${className}`}>
            <audio ref={audioRef} src={fileURL} />
            <div className="controls">
                <div className="play-pause-icon" onClick={togglePlayPause}>
                    <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </div>
                <div className="progress-container">
                    <div className="time">
                        {formatTime(currentTime)} / {formatTime(duration)}
                    </div>
                    <div className="progress-bar">
                        <div
                            className="progress"
                            style={{ width: `${(currentTime / duration) * 100}%` }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MusicPlayer;
