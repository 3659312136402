/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/config";
import { collection, query, getDocs, doc } from "firebase/firestore";

const OtherUserFG = ({ role, uid }) => {
    const [photos, setPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                // Reference to the user's content sub-collection
                const contentRef = collection(doc(db, role, uid), 'store');
                const q = query(contentRef);
                const snapshot = await getDocs(q);

                // Map through documents and store all necessary details
                const fetchedPhotos = snapshot.docs.map(doc => ({
                    id: doc.id,
                    imageUrl: doc.data().imageUrl,
                    description: doc.data().description,
                    productName: doc.data().productName,
                    price: doc.data().price,
                    size: doc.data().size

                }));

                setPhotos(fetchedPhotos);
            } catch (err) {
                console.error("Error fetching photos:", err);
            }
        };

        fetchPhotos();
    }, [role, uid]);

    const PhotoModal = ({ photo, onClose }) => {
        if (!photo) return null;
    
    

    return (
        <div className="modal-overlay" onClick={onClose}>
          <div className="modal-content-fashion" onClick={(e) => e.stopPropagation()}>
            <img src={photo.imageUrl} alt="Selected" className="modal-image-fashion" />
            <div id="modal-details-fashion">
            <p className="modal-description-fashion-gallery"><span id="description-label">Description:</span>  {photo.description}</p>
            </div>
            <p className="modal-product-size"> <span id="size-label"> Size: </span> {photo.size}  </p>
            <p className="modal-product-name-gallery">{photo.productName}</p>
            <p className="modal-product-price-gallery">Rs.{photo.price}</p>
            <span className="close-modal" onClick={onClose}>&times;</span>
            <button id="fashion-buy">buy</button>
            
          </div>
        </div>
      );
    };

    const handlePhotoClick = (photo) => {
        setSelectedPhoto(photo);
    };

    const handleCloseModal = () => {
        setSelectedPhoto(null);
    };



return (
    <div id="social-gallery-layout">
      {photos.map((photo, index) => (
        <div key={index} className="photo-details">
          <img 
            src={photo.imageUrl} 
            id="social-gallery-photos" 
            onClick={() => handlePhotoClick(photo)} 
          />
           <div className="fashion-photodetails">
            <p >{photo.productName}</p>
            <p >Rs.{photo.price}</p>
          </div>
        </div>
      ))}
      {selectedPhoto && (
        <PhotoModal 
          photo={selectedPhoto} 
          onClose={handleCloseModal} 
        />
      )}
    </div>
  );
};

export default OtherUserFG;
