import React, { useState, useContext } from 'react';
import { UserContext } from '../../firebase/userContext';

const Username = () => {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const { handleExplorerUsername, error, cleanError } = useContext(UserContext);

    const handleCreateUsername = async () => {
        cleanError();
        const errorMsg = await handleExplorerUsername(username, name);
        if (!errorMsg) {
            // Handle successful username creation (navigation is already managed in UserContext)
        }
    };

    return (
        <div className="register-layout">
            <h1 id="logo">JUSSST</h1>
            <div id="username-container">
                <p id="create-username">add details</p>
                <input
                    placeholder='name'
                    id='explorer-name-register'
                    value={name}
                    onChange={(e) => setName(e.target.value)} 
                />
                <input
                    placeholder="username"
                    id="explorer-username-register"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <button id="explorer-create-button" onClick={handleCreateUsername}>
                    create
                </button>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
}

export default Username;
