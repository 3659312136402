import React from "react";
import OtherUserSG from "./ouSocialGallery";
import OtherUserFG from "./ouFashionGallery";
import OtherUserMG from "./ouMusicGallery";

const GalleryHandler = ({ category, role, uid }) => {
    const getGalleryComponent = () => {
        if (category) {
            const lowerCaseCategory = category.toLowerCase();
            switch (lowerCaseCategory) {
                case 'social':
                    return <OtherUserSG role={role} uid={uid} />;
                    case 'fashion':
                        return <OtherUserFG role={role} uid={uid} />;
                         case 'music':
                            return <OtherUserMG role={role} uid={uid} />
                default:
                    return <div>No gallery available for this category.</div>;
            }
        } else {
            return <div>No category specified.</div>;
        }
    };

    return getGalleryComponent();
};

export default GalleryHandler;
