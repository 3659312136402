import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../firebase/userContext';
import './register.css';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const { role, setRole, setEmail, setPassword, handleRegister, error } = useContext(UserContext);
    const [localError, setLocalError] = useState('');
    const navigate = useNavigate();

    // Clear local error when the component mounts
    useEffect(() => {
        setLocalError('');
    }, []);

    const handleInputChange = (e) => {
        const { id, value } = e.target;

        // Clear error when user starts typing
        setLocalError('');

        if (id === 'email') {
            setEmail(value);
        } else if (id === 'password') {
            setPassword(value);
        }
    };

    const handleRegisterClick = async () => {
        // Clear local error before starting the registration
        setLocalError('');

        // Execute the registration function and handle errors
        const registrationError = await handleRegister();
        if (registrationError) {
            setLocalError(registrationError);
        }
    };

    // Optional: Reset local error if there's an error from the context
    useEffect(() => {
        if (error !== localError) {
            setLocalError(error);
        }
    }, [error, localError]);

    return (
        <div className="register-layout">
            <div className="register-container">
                <h1 className="logo-register">JUSSST</h1>
                <p id="create-an-account">Create an account</p>
                <h1
                    id="creator"
                    className={role === 'creator' ? 'selected' : ''}
                    onClick={() => setRole('creator')}
                >
                    creator
                </h1>
                <h1
                    id="explorer"
                    className={role === 'explorer' ? 'selected' : ''}
                    onClick={() => setRole('explorer')}
                >
                    explorer
                </h1>
                <input
                    id="email"
                    placeholder="Email"
                    onChange={handleInputChange}
                />
                <input
                    id="password"
                    placeholder="Password"
                    type="password"
                    onChange={handleInputChange}
                />
                <input
                    id="checkbox"
                    type="checkbox"
                />
                <p id="terms">terms and conditions</p>
                <button id="register-button" onClick={handleRegisterClick}>Register</button>
                {localError && <p className="error">{localError}</p>}
            </div>
            <h1 className="login-link" onClick={() => navigate('/login')}>Login</h1>
        </div>
    );
};

export default Register;