import React, { useState, useContext, useEffect } from 'react';
import './profile.css';
import { UserContext } from "../../firebase/userContext";
import settingIcon from '../../assets/icons/SettingIcon.png';
import MusicOptions from "./options/musicOptions";
import FilmOptions from "./options/filmOptions";
import FashionOptions from "./options/fashionOptions";
import SocialOptions from "./options/socialOptions";

const Profile = () => {
    const { currentUser, updateProfile, error, cleanError } = useContext(UserContext);
    const [selectedCategory] = useState(currentUser?.category || '');
    const [selectedImage, setSelectedImage] = useState(null);
    const [name, setName] = useState(currentUser?.name || '');
    const [username, setUsername] = useState(currentUser?.username || '');
    const [workEmail, setWorkEmail] = useState(currentUser?.workEmail || '');
    const [bio, setBio] = useState(currentUser?.bio || '');
    const [errorMessage, setError] = useState('');
    const [selectedOption, setSelectedOption] = useState(currentUser?.option || '');

    useEffect(() => {
        // Reset local error when component mounts
        setError('');
        // Clean error from context if any
        cleanError();
    }, [cleanError]);

    useEffect(() => {
        // Update local error if context error changes
        if (error) {
            setError(error);
        }
    }, [error]);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    }

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const handleImageUpload = () => {
        document.getElementById('fileInput').click();
    };

    const handleSaveProfile = () => {
        const profileData = {
            name,
            username,
            workEmail,
            bio,
            category: selectedCategory,
            option: selectedOption
        };
        updateProfile(profileData, selectedImage, username);
    };

    const categoryId = `category-${selectedCategory.toLowerCase()}`;

    return (
        <div id="profile-layout">
            <h1 id="logo">JUSSST</h1>
            <img src={settingIcon} id="setting-icon" alt="settings" />
            <div id="profile-container">
                <p id="edit-profile">edit profile</p>
                <div id="upload-profilephoto-container" onClick={handleImageUpload}>
                    {selectedImage ? (
                        <img src={URL.createObjectURL(selectedImage)} id="profile-photo" alt="profile" />
                    ) : (
                        currentUser?.profileImageUrl ? (
                            <img src={currentUser.profileImageUrl} id="profile-photo" alt="profile" />
                        ) : (
                            'upload photo'
                        )
                    )}
                </div>

                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleImageChange}
                />
                <p id="name-label-profile">name:</p>
                <input
                    id="name-profile"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

               <p id="username-label-profile">username:</p>
                <input
                    placeholder="username"
                    id="username-profile"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                
                <p id="profession-label-profile">profession:</p>
                <div className="selectcategory-profile" id={categoryId}>
                    {selectedCategory === 'social' && <SocialOptions onChange={handleOptionChange}/>}
                    {selectedCategory === 'music' && <MusicOptions onChange={handleOptionChange} />}
                    {selectedCategory === 'films' && <FilmOptions onChange={handleOptionChange}/>}
                    {selectedCategory === 'fashion' && <FashionOptions onChange={handleOptionChange}/>}
                </div>

                <p id="workemail-label-profile">work-email:</p>
                <input
                    id="email-profile"
                    value={workEmail}
                    onChange={(e) => setWorkEmail(e.target.value)}
                />

                <p id="bio-label-profile">bio:</p>
                <input
                    placeholder="bio"
                    id="bio-profile"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                />
                <button id="profile-save-button" onClick={handleSaveProfile}>save</button>
                {errorMessage && <p className="error2">{errorMessage}</p>}
            </div>
        </div>
    );
}

export default Profile;