/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, doc, addDoc, setDoc, serverTimestamp, getDoc } from "firebase/firestore";
import { db, storage } from "../../../firebase/config";
import MusicPlayer from "../../../components/musicPlayer";
import './upload.css';
import { useNavigate } from "react-router-dom";

const MusicUpload = () => {
    const { currentUser, role } = useContext(UserContext);
    const [fileURL, setFileURL] = useState(null);
    const [fileType, setFileType] = useState("");
    const [file, setFile] = useState(null);
    const [coverFile, setCoverFile] = useState(null);
    const [coverURL, setCoverURL] = useState(null);
    const [title, setTitle] = useState("");
    const [error, setError] = useState("");
    const [playing, setPlaying] = useState(false);

    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const url = URL.createObjectURL(selectedFile);
            if (event.target.name === "songFile") {
                setFileURL(url);
                setFileType(selectedFile.type);
                setFile(selectedFile);
            } else if (event.target.name === "coverFile") {
                setCoverURL(url);
                setCoverFile(selectedFile);
            }
        }
    };

    const handleUpload = async () => {
        if (!file || !coverFile || !title) {
            setError("Please select a song, cover photo, and enter a title.");
            return;
        }

        try {
            const coverStoragePath = `music-gallery/${currentUser.uid}/singles/${coverFile.name}`;
            const coverStorageRef = ref(storage, coverStoragePath);
            await uploadBytes(coverStorageRef, coverFile);
            const coverDownloadURL = await getDownloadURL(coverStorageRef);

            const storagePath = `music-gallery/${currentUser.uid}/singles/${file.name}`;
            const storageRef = ref(storage, storagePath);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);

            const singlesDocRef = doc(db, `${role}/${currentUser.uid}/music`, "singles");
            const singlesDoc = await getDoc(singlesDocRef);

            if (!singlesDoc.exists()) {
                await setDoc(singlesDocRef, { createdAt: serverTimestamp() });
            }

            const singlesSubCollectionRef = collection(singlesDocRef, "tracks");
            await addDoc(singlesSubCollectionRef, {
                title: title,
                fileURL: downloadURL,
                coverURL: coverDownloadURL,
                fileType: fileType,
                timestamp: serverTimestamp(),
                username: currentUser.username,
                name: currentUser.name
            });

            setFileURL(null);
            setCoverURL(null);
            setFileType("");
            setFile(null);
            setCoverFile(null);
            setTitle("");
            setError("");
            navigate(`/creator/${currentUser.username}`);
        } catch (err) {
            console.error("Error uploading file:", err);
            setError("Failed to upload file. Please try again.");
        }
    };

    const handlePlayPause = () => {
        setPlaying(prevPlaying => !prevPlaying);
    };

    return (
        <div id="upload-layout">
            <h1 id="logo">JUSSST</h1>
            <div id="single-layout">
                <div id="single-cover-photo" onClick={() => document.getElementById('coverInput').click()}>
                    {coverURL ? <img src={coverURL} id="single-cover-photo-preview" /> : "Upload Cover Photo"}
                </div>
                <input
                    type="file"
                    id="coverInput"
                    name="coverFile"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <p id="upload-single">Upload Single</p>
                <input
                    type="text"
                    placeholder="Single Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    id="single-title-upload"
                />
                <input
                    type="file"
                    name="songFile"
                    accept="audio/mp3, video/mp4"
                    onChange={handleFileChange}
                    id="upload-single-track"
                />
                <button id='single-track-upload-button' type="submit" onClick={handleUpload}>Upload</button>

                {/* Music player component */}
                {fileURL && (
                    <>
                        <MusicPlayer 
                            fileURL={fileURL} 
                            title={title} 
                            className='audio-control-upload' 
                            isPlaying={playing} 
                            onPlay={() => setPlaying(true)}
                            onPause={() => setPlaying(false)}
                        />
                        <button onClick={handlePlayPause}>
                            {playing ? 'Pause' : 'Play'}
                        </button>
                    </>
                )}
            </div>

            {error && <p>{error}</p>}
        </div>
    );
};

export default MusicUpload;
