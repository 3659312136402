import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
    const [enteredPassword, setEnteredPassword] = useState(""); // Track user input
    const correctPassword = "jussstexplore"; // Fixed password
    const navigate = useNavigate();

    // Track what the user types
    const handlePasswordChange = (e) => {
        setEnteredPassword(e.target.value);
    };

    // Check if the entered password matches the fixed password
    const handlePasswordSubmit = () => {
        if (enteredPassword === correctPassword) {
            alert("Access granted!");
            navigate('/register')
        } else {
            alert("Incorrect password. Try again.");
        }
    };

    return (
        <div className="register-layout"> 
            
            <input
                type="password"
                placeholder="Enter password"
                value={enteredPassword} // User input is tracked
                onChange={handlePasswordChange} // Track input
            />
            <button onClick={handlePasswordSubmit}>Enter</button>
        </div>
    );
}

export default Landing;
