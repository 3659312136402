import React from "react";
import './App.css'
import { UserProvider } from "./firebase/userContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./pages/register/register";
import Category from "./pages/register/category";
import Profile from "./pages/profile/profile";
import Creator from "./pages/profile/creator";
import UploadHandler from "./pages/profile/upload/uploadHandler";
import Home from "./pages/home/home";
import SocialHome from "./pages/home/socialHome";
import FashionHome from "./pages/home/fashionHome";
import OtherUser from "./pages/profile/otherUsers/otherUser";
import Username from "./pages/register/username";
import Notification from "./pages/profile/notification/notification";
import Settings from "./pages/settings/settings";
import Login from "./pages/register/login";
import ExplorerProfile from "./pages/profile/explorer/explorerProfile";
import MusicHome from "./pages/home/musicHome";
import Landing from "./pages/landing";




const App = () => {
  return (
    <Router>
      <UserProvider>

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/register" element={<Register />} />
        <Route path="username" element={<Username />} />
        <Route path="/category" element={<Category />} />
        <Route path="/profile/:username" element={<Profile />} />
        <Route path="/creator/:username" element={<Creator />} />
        <Route path="/upload/:category" element={<UploadHandler />} />
        <Route path="/home/:username" element={<Home />} />
        <Route path="/social/:username" element={<SocialHome />} />
        <Route path="/fashion/:username" element={<FashionHome />} />
        <Route path="/music/:username" element={<MusicHome />} />
        <Route path="/creators/:username" element={<OtherUser />} />
        <Route path="/notification/:username" element={<Notification />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/login" element={<Login />} />
        <Route path="/explorer/:username" element={<ExplorerProfile />} />
      
      </Routes>
    </UserProvider>
    </Router>
  )
}

export default App;