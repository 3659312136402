import React, { useState, useContext } from 'react';
import { UserContext } from '../../firebase/userContext';


const Category = () => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const { handleCategoryAndUsername } = useContext(UserContext);

    const handleCategorySelection = (category) => {
        setSelectedCategory(category);
        setError(''); // Clear error when category is selected
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        setError(''); // Clear error when username is changed
    };

    const handleCreate = async () => {
        setError(''); // Clear any previous error before attempting to create
        const errorMessage = await handleCategoryAndUsername(selectedCategory, username);
        if (errorMessage) {
            setError(errorMessage); // Set the error to be displayed
        }
    };

    return (
        <div className="register-layout">
            <h1 id="logo">JUSSST</h1>
            <div className="register-container">
                <p id="choose-category">Choose a category</p>
               
                <h1 
                    className={`categories ${selectedCategory === 'social' ? 'selected' : ''}`}
                    id="social"
                    onClick={() => handleCategorySelection('social')}
                >
                    social
                </h1>
                
                {/* Uncomment these if needed */}
                <h1 
                    className={`categories ${selectedCategory === 'music' ? 'selected' : ''}`}
                    id="music"
                    onClick={() => handleCategorySelection('music')}
                >
                    music
                </h1>
                
                {/* <h1 
                    className={`categories ${selectedCategory === 'films' ? 'selected' : ''}`}
                    id="films"
                    onClick={() => handleCategorySelection('films')}
                >
                    Films
                </h1> */}
               
                <h1 
                    className={`categories ${selectedCategory === 'fashion' ? 'selected' : ''}`}
                    id="fashion"
                    onClick={() => handleCategorySelection('fashion')}
                >
                    fashion
                </h1>
                 
                <input
                    id="username"
                    placeholder="Username" 
                    value={username}
                    onChange={handleUsernameChange}
                />
                <button id="category-button" onClick={handleCreate}>Create</button>
                {error && <p className="error2">{error}</p>}
            </div>
        </div>
    );
};

export default Category;