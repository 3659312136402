import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../firebase/userContext';
import { db, collection, onSnapshot, query } from '../../../firebase/config';
import './notification.css';
import NavBar from '../../../components/navBar';

const Notification = () => {
    const { currentUser } = useContext(UserContext);
    const [followers, setFollowers] = useState([]);

    useEffect(() => {
        if (!currentUser) return;

        const followersRef = collection(db, currentUser.role, currentUser.uid, 'followers');
        const q = query(followersRef);

        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newFollowers = snapshot.docs.map(doc => doc.data().username);
            setFollowers(newFollowers);
        });

        return () => unsubscribe();
    }, [currentUser]);

    return (
        <div className="register-layout">
            <h1 id='logo'>JUSSST</h1>
            <NavBar />
            <div id="notification-container">
                <p id="notification">Notifications</p>
                <div id="followers-container">
                    {followers.map((username, index) => (
                        <p id='notification-line' key={index}> <span id='username-notification'> @{username} </span> has started following you</p>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Notification;
