import React, { useState, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../../firebase/config";
import './upload.css';
import { useNavigate } from "react-router-dom";

const SocialUpload = () => {
    const { currentUser, role } = useContext(UserContext);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const triggerFileInput = () => {
        document.getElementById('image-input').click();
    };

    const handleUpload = async () => {
        if (!imageFile || !description) {
            setError("Please select an image and enter a description.");
            return;
        }

        try {
            // Upload image to Firebase Storage
            const storageRef = ref(storage, `social-gallery/${currentUser.uid}/${imageFile.name}`);
            await uploadBytes(storageRef, imageFile);
            const downloadURL = await getDownloadURL(storageRef);

            // Save image data and description in Firestore under the user's sub-collection
            const contentRef = collection(db, `${role}/${currentUser.uid}/content`);
            await addDoc(contentRef, {
                imageUrl: downloadURL,
                description: description,
                username: currentUser.username,
                timestamp: serverTimestamp(),
                name: currentUser.name
            });

            // Clear the form after successful upload
            setImagePreview(null);
            setImageFile(null);
            setDescription("");
            setError("");
            navigate(`/creator/${currentUser.username}`)
        } catch (err) {
            console.error("Error uploading image:", err);
            setError("Failed to upload image. Please try again.");
        }
    };

    return (
        <div id="upload-layout">
            <h1 id="logo">JUSSST</h1>
            <div id="upload-container">
                <p id="upload-message-social">upload content</p>
               
                <div id="upload-image" onClick={triggerFileInput}>
                    {imagePreview ? (
                        <img src={imagePreview} alt="Preview" id="social-image-preview" />
                    ) : (
                        "upload photo"
                    )}
                </div>

                <input
                    id="image-input"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                />

                <input
                    id="social-upload-description" 
                    placeholder="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                {error && <p id="upload-error-message">{error}</p>}

                <button id="upload-social-button" onClick={handleUpload}>
                    upload
                </button>                
            </div>
        </div>
    );
}

export default SocialUpload;
