import React from "react";
import './home.css'
import NavBar from "../../components/navBar";
import { useNavigate, useParams } from "react-router-dom";



const Home = () => {
    const navigate = useNavigate();
    const { username } = useParams();

    
    
    return (
        <div id="home-layout">
            <h1 id="logo">JUSSST</h1>
            <NavBar />
            <div id="home-select">
            <h1 id="social-home" onClick={() => navigate(`/social/${username}`)}>social</h1>
            <h1 id="fashion-home" onClick={() => navigate(`/fashion/${username}`)}>fashion</h1>
            <h1 id="music-home" onClick={() => navigate(`/music/${username}`)}>music</h1>
           </div>
        </div>
    )
}

export default Home;