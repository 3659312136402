import React from "react";
import { useParams } from "react-router-dom";
import SocialUpload from "./socialUpload";
import StoreUpload from "./storeUpload";
import MusicUpload from "./musicUpload";

const UploadHandler = () => {
  const { category } = useParams();

  const renderUploadPage = () => {
    switch (category) {
      case 'social':
        return <SocialUpload />;
      case 'fashion':
        return <StoreUpload />;
     
        case 'music':
        return <MusicUpload />;
      default:
        return <div>Invalid category</div>;
    }
  };

  return <div>{renderUploadPage()}</div>;
};

export default UploadHandler;
