/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { db, collection, doc, getDocs, deleteDoc } from "../../../firebase/config";
import './gallery.css';

const FashionGallery = () => {
  const { currentUser } = useContext(UserContext);
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const contentRef = collection(doc(db, 'creator', currentUser.uid), 'store');

      getDocs(contentRef).then((querySnapshot) => {
        const fetchedPhotos = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.imageUrl) {
            fetchedPhotos.push({
              id: doc.id,
              imageUrl: data.imageUrl,
              description: data.description,
              productName: data.productName,
              price: data.price,
              size: data.size,
             
            });
          }
        });
       
        setPhotos(fetchedPhotos);
      }).catch((error) => {
        console.error('Error fetching photos', error);
      });
    }
  }, [currentUser]);

  const handleDelete = async (photo) => {
    try {
      // Check if photo.id is defined
      if (!photo.id) {
        console.error('Document ID is missing');
        return;
      }
      
      // Delete from Firestore
      const photoDocRef = doc(db, 'creator', currentUser.uid, 'store', photo.id);
      await deleteDoc(photoDocRef);

      
  
      // Update local state
      setPhotos((prevPhotos) => prevPhotos.filter((p) => p.id !== photo.id));
      setSelectedPhoto(null);
    } catch (error) {
      console.error('Error deleting photo from Firestore', error);
    }
  };
  

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  const PhotoModal = ({ photo, onClose }) => {
    if (!photo) return null;
    
   
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content-fashion" onClick={(e) => e.stopPropagation()}>
          <img src={photo.imageUrl} alt="Selected" className="modal-image-fashion" />
          <div id="modal-details-fashion">
          <p className="modal-description-fashion-gallery"><span id="description-label">Description:</span>  {photo.description}</p>
          </div>
          <p className="modal-product-size"> <span id="size-label"> Size: </span> {photo.size}  </p>
          <p className="modal-product-name-gallery">{photo.productName}</p>
          <p className="modal-product-price-gallery">Rs.{photo.price}</p>
          <span className="close-modal" onClick={onClose}>&times;</span>
          <button id="fashion-delete" onClick={() => handleDelete(photo)}>delete</button>
        </div>
      </div>
    );
  };
  
    return (
        <div id="social-gallery-layout">
          {photos.map((photo, index) => (
            <div key={index} className="photo-details">
              <img 
                src={photo.imageUrl} 
                id="social-gallery-photos" 
                onClick={() => handlePhotoClick(photo)} 
              />
               <div className="fashion-photodetails">
                <p >{photo.productName}</p>
                <p >Rs.{photo.price}</p>
              </div>
            </div>
          ))}
          {selectedPhoto && (
            <PhotoModal 
              photo={selectedPhoto} 
              onClose={handleCloseModal} 
            />
          )}
        </div>
      );
    };
    
    export default FashionGallery;