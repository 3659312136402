/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import './navBar.css'
import profileIcon from '../assets/icons/profile.png'
import notificationIcon from '../assets/icons/notification.png'
import { useNavigate } from "react-router-dom";
import { UserContext } from "../firebase/userContext";


const NavBar = () => {
    const navigate = useNavigate();
    const { role, currentUser } = useContext(UserContext);

    const profilePath = role === 'creator' ? `/creator/${currentUser.username}` : `/explorer/${currentUser.username}`;

    return (
        <div id="navbar-container">
            <img src={profileIcon} onClick={() => navigate(profilePath)} id="profile-icon" />
            <img src={notificationIcon} onClick={() => navigate(`/notification/${currentUser.username}`)} id="notification-icon" />
            <h1 id="explore-nav" onClick={() => navigate(`/home/${currentUser.username}`)}>explore</h1>
        </div>
    )
}

export default NavBar;