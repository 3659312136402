/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/config";
import { collection, query, getDocs, doc } from "firebase/firestore";

const OtherUserSG = ({ role, uid }) => {
    const [photos, setPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                // Reference to the user's content sub-collection
                const contentRef = collection(doc(db, role, uid), 'content');
                const q = query(contentRef);
                const snapshot = await getDocs(q);

                // Map through documents and store all necessary details
                const fetchedPhotos = snapshot.docs.map(doc => ({
                    id: doc.id,
                    imageUrl: doc.data().imageUrl,
                    description: doc.data().description,
                    timestamp: doc.data().timestamp
                }));

                const sortedPhotos = fetchedPhotos.sort((a, b) => b.timestamp?.toDate() - a.timestamp?.toDate());

                setPhotos(sortedPhotos);
            } catch (err) {
                console.error("Error fetching photos:", err);
            }
        };
        

        fetchPhotos();
    }, [role, uid]);

    const PhotoModal = ({ photo, onClose }) => {
        if (!photo) return null;

        const formattedDate = photo.timestamp 
    ? new Date(photo.timestamp.toDate()).toLocaleDateString() 
    : '';
    
        return (
            <div className="modal-overlay">
                <div className="modal-content-social">
                <span className="close-modal" onClick={onClose}>&times;</span>
                    <img src={photo.imageUrl} alt="Photo" className="modal-image-social" />
                    <p className="modal-description">{photo.description}</p>
                    <p className="modal-timestamp">{formattedDate}</p>
                </div>
            </div>
        );
    };

    const handlePhotoClick = (photo) => {
        setSelectedPhoto(photo);
    };

    const handleCloseModal = () => {
        setSelectedPhoto(null);
    };

    return (
        <div id="ou-social-gallery">
            {photos.map(photo => (
                <img
                    key={photo.id}
                    src={photo.imageUrl}
                    alt="Photo"
                    id="social-gallery-photos"
                    onClick={() => handlePhotoClick(photo)}
                />
            ))}
            {selectedPhoto && (
                <PhotoModal
                photo={selectedPhoto}
                onClose={handleCloseModal} 
                />
            )}
        </div>
    );
};

export default OtherUserSG;
