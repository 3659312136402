import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../firebase/userContext";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../../firebase/config";
import MusicPlayer from "../../../components/musicPlayer";
import deleteIcon from '../../../assets/icons/delete.png';

const MusicGallery = () => {
    const { currentUser, role } = useContext(UserContext);
    const [tracks, setTracks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [playingTrackId, setPlayingTrackId] = useState(null);

    useEffect(() => {
        const fetchTracks = async () => {
            setLoading(true);
            setError("");

            try {
                const tracksCollectionRef = collection(db, `${role}/${currentUser.uid}/music/singles/tracks`);
                const tracksSnapshot = await getDocs(tracksCollectionRef);

                const tracksList = tracksSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setTracks(tracksList);
            } catch (err) {
                console.error("Error fetching tracks:", err);
                setError("Failed to load tracks. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchTracks();
    }, [currentUser.uid, role]);

    const deleteTrack = async (trackId) => {
        try {
            const trackDocRef = doc(db, `${role}/${currentUser.uid}/music/singles/tracks/${trackId}`);
            await deleteDoc(trackDocRef);
            setTracks(tracks.filter(track => track.id !== trackId));
            if (playingTrackId === trackId) {
                setPlayingTrackId(null); // Stop playback if the currently playing track is deleted
            }
        } catch (err) {
            console.error("Error deleting track:", err);
            setError("Failed to delete track. Please try again.");
        }
    };

    const handlePlay = (trackId) => {
        if (playingTrackId !== trackId) {
            setPlayingTrackId(trackId); // Update playing track ID
        }
    };

    return (
        <div id="music-gallery-layout">
            {loading && <p>Loading tracks...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && tracks.length > 0 && (
                <ul>
                    {tracks.map(track => (
                        <li key={track.id} className="track-item">
                            <img
                                src={track.coverURL}
                                alt={`${track.title} cover`}
                                className="track-cover"
                            />
                            <div className="track-info">
                                <strong>{track.title}</strong>
                                <MusicPlayer
                                    fileURL={track.fileURL}
                                    className="custom-music-player"
                                    isPlaying={playingTrackId === track.id}
                                    onPlay={() => handlePlay(track.id)}
                                    onPause={() => { if (playingTrackId === track.id) setPlayingTrackId(null); }}
                                />
                            </div>
                            <img 
                                src={deleteIcon} 
                                id="delete-icon" 
                                onClick={() => deleteTrack(track.id)}  
                                alt="Delete" 
                                style={{ cursor: 'pointer' }} 
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default MusicGallery;