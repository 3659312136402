import React from "react";

const FilmOptions = ({ onChange }) => {
    return (
        <div>
            <select id="social-category" onChange={onChange}>
                <option value="">Please select</option>
                <option value="Indie-Filmmaker">Indie Filmmaker</option>
                <option value="Production House"></option>
            </select>
        </div>
    );
}

export default FilmOptions;
