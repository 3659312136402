/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import './explorer.css';
import { UserContext } from "../../../firebase/userContext";
import { doc, getDoc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/config";
import NavBar from "../../../components/navBar";
import settingIcon from '../../../assets/icons/SettingIcon.png'
import { useNavigate } from "react-router-dom";

const ExplorerProfile = () => {
    const { currentUser } = useContext(UserContext);
    const [userData, setUserData] = useState({ name: '', username: '' });
    const [editMode, setEditMode] = useState(false);
    const [newName, setNewName] = useState('');
    const [newUsername, setNewUsername] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                try {
                    const userDoc = await getDoc(doc(db, 'explorer', currentUser.uid));
                    if (userDoc.exists()) {
                        const data = userDoc.data();
                        setUserData(data);
                        setNewName(data.name);
                        setNewUsername(data.username);
                    } else {
                        console.log('No such document');
                    }
                } catch (error) {
                    console.error('Error fetching user data: ', error);
                }
            }
        };

        fetchUserData();
    }, [currentUser]);

    const handleSave = async () => {
        setError('');

        // Check if the new username is unique
        try {
            const collectionsToCheck = ['explorer', 'creator'];
            let isUsernameTaken = false;

            for (const collectionName of collectionsToCheck) {
                const roleCollection = collection(db, collectionName);
                const q = query(roleCollection, where('username', '==', newUsername));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty && newUsername !== userData.username) {
                    isUsernameTaken = true;
                    break;
                }
            }

            if (isUsernameTaken) {
                setError('Username is already taken');
                return;
            }

            // Update the Firestore document with merge: true to retain other fields
            await setDoc(doc(db, 'explorer', currentUser.uid), {
                name: newName,
                username: newUsername
            }, { merge: true });

            // Update local state and exit edit mode
            setUserData({ ...userData, name: newName, username: newUsername });
            setEditMode(false);

        } catch (error) {
            console.error('Error updating user data: ', error);
            setError('Failed to save changes. Please try again.');
        }
    };

    return (
        <div className="register-layout">
            <h1 id="logo" >JUSSST</h1>
            <NavBar />
            <img src={settingIcon} id="setting-icon" onClick={() => navigate('/settings')}/>
            <div id="explorer-container">
                {editMode ? (
                    <>

                        <p id="explorer-profile-details">edit profile</p>
                        <p id="explorer-name-label">name:</p>
                        <p id="explorer-username-label">username:</p>
                        <input
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            placeholder="Name"
                            id="explorer-name"
                        />
                        <input
                            type="text"
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                            placeholder="Username"
                            id="explorer-username"
                        />
                        {error && <p className="error-message">{error}</p>}
                        <button  id="explorer-button-profile" onClick={handleSave}>Save</button>
                    </>
                ) : (
                    <>
                        <p id="explorer-profile-details">profile details</p>
                        <p id="explorer-name-label">name:</p>
                        <p id="explorer-username-label">username:</p>
                        <input id='explorer-name' value={userData.name}/>
                        <input id="explorer-username" value={userData.username} />
                        <button id='explorer-button-profile' onClick={() => setEditMode(true)}>Edit</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default ExplorerProfile;
