import React from "react";

const MusicOptions = ({ onChange }) => {
    return (
        <div>
            <select id="social-category" onChange={onChange}>
                <option value="">Please select</option>
                <option value="Artist">Artist</option>
                <option value="Band">Band</option>
               
               
            </select>
        </div>
    );
}

export default MusicOptions;
