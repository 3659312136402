/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { db, collection, doc, getDocs, deleteDoc } from "../../../firebase/config";
import './gallery.css';

const SocialGallery = () => {
  const { currentUser } = useContext(UserContext);
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const contentRef = collection(doc(db, 'creator', currentUser.uid), 'content');

      getDocs(contentRef).then((querySnapshot) => {
        const fetchedPhotos = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.imageUrl) {
            fetchedPhotos.push({
              id: doc.id,
              imageUrl: data.imageUrl,
              description: data.description,
              timestamp: data.timestamp
            });
          }
        });
        fetchedPhotos.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
        setPhotos(fetchedPhotos);
      }).catch((error) => {
        console.error('Error fetching photos', error);
      });
    }
  }, [currentUser]);

  const handleDelete = async (photo) => {
    try {
      // Check if photo.id is defined
      if (!photo.id) {
        console.error('Document ID is missing');
        return;
      }
      
      // Delete from Firestore
      const photoDocRef = doc(db, 'creator', currentUser.uid, 'content', photo.id);
      await deleteDoc(photoDocRef);

      
  
      // Update local state
      setPhotos((prevPhotos) => prevPhotos.filter((p) => p.id !== photo.id));
      setSelectedPhoto(null);
    } catch (error) {
      console.error('Error deleting photo from Firestore', error);
    }
  };
  

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  const PhotoModal = ({ photo, onClose }) => {
    if (!photo) return null;
    
    // const formattedDate = photos.timestamp ? new Date(photos.timestamp?.toDate()).toLocaleDateString() : '';
    const formattedDate = photo.timestamp 
    ? new Date(photo.timestamp.toDate()).toLocaleDateString() 
    : '';
    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content-social" onClick={(e) => e.stopPropagation()}>
          <img src={photo.imageUrl} alt="Selected" className="modal-image-social" />
          <p className="modal-description">{photo.description}</p>
          <p className="modal-timestamp">{formattedDate}</p>
          <span className="close-modal" onClick={onClose}>&times;</span>
          {/* <button className="close-modal" onClick={onClose}>X</button> */}
          <button id="social-delete" onClick={() => handleDelete(photo)}>delete</button>
        </div>
      </div>
    );
  };
  return (
    <div id="social-gallery-layout">
      {photos.map((photo, index) => (
        <img 
          key={index} 
          src={photo.imageUrl} 
          id="social-gallery-photos" 
          onClick={() => handlePhotoClick(photo)} 
        />
      ))}
      {selectedPhoto && (
        <PhotoModal 
        photo={selectedPhoto} 
        onClose={handleCloseModal} 
        
        
        />
      )}
    </div>
  );
};

export default SocialGallery;
