import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../firebase/userContext";

const Login = () => {
    const navigate = useNavigate();
    const { setEmail, setPassword, handleLogin, error } = useContext(UserContext)

    return (
        <div className="register-layout">
            <h1 id="logo">JUSSST</h1>
            <div id="login-container">
                <p id="login">login</p>
                <input
                id="login-email"
                placeholder="email" 
                onChange={(e) => setEmail(e.target.value)}
                />
                <input
                id="login-password"
                placeholder="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)} 
                />
                 {error && <p id="error-message">{error}</p>}
                <p id="forgot-password">forgot password</p>
                <button id="login-button" onClick={handleLogin}>login</button>
            </div>
            <h1 id="login-signup" onClick={() => navigate('/register')}>sign up</h1>
        </div>
    )
}

export default Login;