import { initializeApp } from "firebase/app";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection,collectionGroup, doc,
  setDoc, addDoc, query,deleteDoc, where, getDocs, getDoc, onSnapshot, serverTimestamp, writeBatch } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject, getMetadata, listAll  } from 'firebase/storage'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


export { auth, db, storage, collectionGroup, createUserWithEmailAndPassword, listAll,
  collection, doc, setDoc, addDoc, query, getDoc, deleteObject, writeBatch,
  where, ref, uploadBytes, getDocs,getMetadata, getDownloadURL, onSnapshot, deleteDoc, serverTimestamp };
