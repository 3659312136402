import React, { useEffect, useState } from "react";
import NavBar from "../../components/navBar";
import { db } from "../../firebase/config"; // Removed unused imports
import { collectionGroup, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const FashionHome = ({ role }) => { // Assuming role is passed as a prop
    const [photos, setPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                // Reference to all 'store' sub-collections across users
                const storeCollectionGroup = collectionGroup(db, 'store');
                const snapshot = await getDocs(storeCollectionGroup);

                // Map through documents and store the image URLs and other necessary details
                const fetchedPhotos = snapshot.docs.map(doc => ({
                    id: doc.id,
                    imageUrl: doc.data().imageUrl,
                    name: doc.data().name,
                    productName: doc.data().productName,
                    price: doc.data().price,
                    size: doc.data().size,
                    username: doc.data().username || 'Unknown', // Assuming username is stored in the doc, otherwise set to 'Unknown'
                    uid: doc.ref.parent.parent.id, // Get the UID of the user from the document path
                    description: doc.data().description // Include description if needed
                }));

                setPhotos(fetchedPhotos);
            } catch (err) {
                console.error("Error fetching photos:", err);
            }
        };

        fetchPhotos();
    }, []);

    const handlePhotoClick = (photoId) => {
        const photo = photos.find(p => p.id === photoId);
        if (photo) {
            setSelectedPhoto(photo);
        } else {
            console.error("Photo not found in state!");
        }
    };

    const closeModal = () => {
        setSelectedPhoto(null);
    };

    return (
        <div id="home-layout">
            <h1 id="logo">JUSSST</h1>
            <NavBar />
            <div id="fashion-container">
                {photos.map(photo => (
                    <div key={photo.id} className="photo-card" onClick={() => handlePhotoClick(photo.id)}>
                        
                        
                        <h1 className="photo-name-fashion">{photo.name}</h1>
                        <img
                            src={photo.imageUrl}
                            alt="Uploaded"
                            className="fashion-photos"
                        />
                        <div className="fashion-photodetails">
                            <p >{photo.productName}</p>
                            <p >Rs.{photo.price}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Photo Modal */}
            {selectedPhoto && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content-fashion" onClick={e => e.stopPropagation()}>
                        <span className="close-modal" onClick={closeModal}>&times;</span>
                        <img src={selectedPhoto.imageUrl} alt="Selected" className="modal-image-fashion" />
                        
                            <p className="modal-username-social" onClick={() => navigate(`/creators/${selectedPhoto.username}`)}>@{selectedPhoto.username}</p>
                            <div id="modal-details-fashion">
                            <p className="modal-description-fashion-gallery"><span id="description-label">Description:</span>  {selectedPhoto.description}</p>
                           </div>
                           <p className="modal-product-size"> <span id="size-label"> Size: </span> {selectedPhoto.size}  </p>
                           
                            <h1 className="modal-name-fashion">{selectedPhoto.name}</h1>
                            <p className="modal-product-name-gallery">{selectedPhoto.productName}</p>
                            <p className="modal-product-price-gallery">Rs.{selectedPhoto.price}</p>
                            <button id="fashion-buy">buy</button>
                      
                    </div>
                </div>
            )}
        </div>
    );
};

export default FashionHome;