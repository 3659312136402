import React from "react";

const FashionOptions = ({ onChange }) => {
    return (
        <div>
            <select id="social-category" onChange={onChange}>
                <option value="">Please select</option>
                <option value="Designer">Designer</option>
                <option value="Brand">Brand</option>
                <option value="Thrift">Thrift</option>
              
            </select>
        </div>
    );
}

export default FashionOptions;
