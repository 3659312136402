/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";
import { UserContext } from "../../firebase/userContext";
import settingIcon from '../../assets/icons/SettingIcon.png'
import uploadIcon from '../../assets/icons/upload.png'
import NavBar from "../../components/navBar";
import { useNavigate } from "react-router-dom";
import messageIcon from '../../assets/icons/Message.png'




const Creator = () => {
    const { currentUser, navigateToUpload, getGalleryComponent } = useContext(UserContext);
    const navigate = useNavigate();
    const [showEmail, setShowEmail] = useState(false);

    const toggleEmailPopup = () => {
        setShowEmail(!showEmail);
    }
    
    
    return (
        <div id="profile-layout">
           <img src={settingIcon} onClick={() => navigate('/settings')} id="setting-icon"/>
           <img src={uploadIcon} id='upload-icon' onClick={navigateToUpload}/>
           <img src={messageIcon} id="message-icon" onClick={toggleEmailPopup}/>
           <NavBar />

           <h1 id="profile-name">{currentUser?.name || ""} </h1>
           
           <div id="profile-photo-container">
            {currentUser?.profileImageUrl && (
                <img src={currentUser.profileImageUrl} id="creator-profilephoto"/>
            )}
           </div>
           
           <p id="creator-select"> {currentUser?.option || ""} </p>
           
           <button id="edit-profile-button" onClick={() => navigate(`/profile/${currentUser?.username}`)}>edit profile</button>
           
           <p id="creator-username"> @{currentUser?.username || ""} </p>
           
           <div id="creator-bio"> {currentUser?.bio || ""} </div>
           {getGalleryComponent()}

           {showEmail && (
            <div id="email-popup">
                <p>{currentUser?.workEmail || "not available"} </p>
                <button  id="emailpop-button" onClick={toggleEmailPopup}>x</button>
            </div>
           )}

        </div>
    )
}

export default Creator;